import * as _ from 'lodash-es';
import { HttpClient } from '@repo/core/api';
import { Practice } from '@/core/models';
import { ResourceConfig } from '@repo/core/resources';

const http = new HttpClient({ baseURL: ResourceConfig.clientPortalServerBase });

export const useUserPracticeService = () => {
  const getPractices = async (): Promise<Practice[]> => {
    try {
      const response = await http.get('userPractices?$expand=practice');
      return _.map(response.data, (x) => x.practice);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    getPractices
  };
};

<script setup lang="ts">
import { onBeforeUnmount, onMounted } from 'vue';
import { DateTimeSchema, MessageSchema, NumberSchema } from '@/locales';
import { useI18n } from 'vue-i18n';

// locale
const { t } = useI18n<[MessageSchema, DateTimeSchema, NumberSchema]>();

// events
const onPreloadError = (event: Event) => {
  event.preventDefault();
  console.log('onPreloadError');
  window.location.reload();
};

const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

// hooks
onMounted(() => {
  window.addEventListener('vite:preloadError', onPreloadError);
});

onBeforeUnmount(() => {
  window.removeEventListener('vite:preloadError', onPreloadError);
});
</script>

<template>
  <div v-if="isMobile()" class="tw-p-5">
    <h3 class="tw-flex tw-justify-center tw-align-middle tw-p-5 tw-mt-24">
      {{ t('layout.app_main.important_notice') }}
    </h3>
    <div class="tw-flex tw-justify-center tw-align-middle tw-p-5 tw-text-xl">
      {{ t('layout.app_main.mobile_info') }}
    </div>
  </div>
  <RouterView v-else />
</template>

<style scoped lang="scss" />

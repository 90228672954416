import { ref } from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { useFileService } from '@/core/services';
import { File as StoredFile } from '@/core/models';

export const useFileStore = defineStore('file', () => {
  // services
  const fileService = useFileService();

  // state
  const files = ref<StoredFile[]>([]);
  const currentPath = ref<string>('');

  // getters

  // actions
  const listFiles = async (path: string | null | undefined) => {
    if (path) currentPath.value = path;

    files.value = await fileService.list(currentPath.value);
    return files.value;
  };

  const upload = async (path: string, files: File[]) => {
    const result = await fileService.upload(path, files);
    if (path === currentPath.value) await listFiles(currentPath.value);

    return result;
  };

  const deleteFiles = (filesToDelete: StoredFile[]) => {
    return fileService.deleteFiles(filesToDelete);
  };

  const download = (path: string, filesToDownload: StoredFile[]) => {
    return fileService.download(path, filesToDownload);
  };

  const createFolder = (path: string) => {
    return fileService.createFolder(path);
  };

  const sendToDraftworx = (
    engagementId: string | undefined,
    draftworxPath: string | undefined,
    path: string | undefined,
    filesToSend: StoredFile[]
  ) => {
    return fileService.sendToDraftworx(engagementId, draftworxPath, path, filesToSend);
  };

  const move = (filesToMove: StoredFile[], destinationPath: string) => {
    return fileService.move(filesToMove, destinationPath);
  };

  const rename = (path: string, fileToRename: StoredFile, name: string) => {
    return fileService.rename(path, fileToRename, name);
  };

  const exists = (paths: string[]): Promise<boolean> => {
    return fileService.exists(paths);
  };

  const hasContent = (paths: string[]): Promise<any[]> => {
    return fileService.hasContent(paths);
  };

  const search = (query: string): Promise<StoredFile[]> => {
    return fileService.search(query);
  };

  const state = {
    files,
    currentPath
  };

  const actions = {
    listFiles,
    upload,
    deleteFiles,
    download,
    createFolder,
    sendToDraftworx,
    move,
    rename,
    exists,
    hasContent,
    search
  };

  return { ...state, ...actions };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFileStore, import.meta.hot));
}

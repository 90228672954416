import { HttpClient } from '@repo/core/api';
import { User } from '@/core/models';
import { ResourceConfig } from '@repo/core/resources';

const http = new HttpClient({ baseURL: ResourceConfig.clientPortalServerBase });

export const useUserService = () => {
  const current = async (): Promise<User> => {
    try {
      const response = await http.get('users/current');
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const updateCurrent = async (data: User) => {
    try {
      const response = await http.post('users/updateCurrent', data);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    current,
    updateCurrent
  };
};

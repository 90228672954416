import { HttpClient } from '@repo/core/api';
import { Notification } from '@/core/models';
import { ResourceConfig } from '@repo/core/resources';

const http = new HttpClient({ baseURL: ResourceConfig.notificationServerBase });

export const useCommonNotificationsService = () => {
  const get = async (parameters?: string, options?: any): Promise<Notification[]> => {
    if (!options) options = {};
    options.autoSave = options.autoSave != null ? options.autoSave : true;

    let url = 'notifications/?$filter=targetProduct eq 2';
    if (parameters) url += `&${parameters}`;

    const res = await http.get(url, { headers: options });
    return res.data;
  };

  const count = async (parameters?: string, options?: any): Promise<number> => {
    if (!options) options = {};
    options.autoSave = options.autoSave != null ? options.autoSave : true;

    let url = 'notifications/$count/?$filter=targetProduct eq 2';
    if (parameters) url += `&${parameters}`;

    const res = await http.get(url, { headers: options });
    return res.data;
  };

  const markAsSeen = async (keys: string[], options?: any): Promise<void> => {
    try {
      if (!options) options = {};
      options.autoSave = options.autoSave != null ? options.autoSave : true;

      await http.post('notifications/markAsSeen', keys, { headers: options });
    } catch (error) {
      console.error('Error marking notifications as unseen:', error);
      throw error;
    }
  };

  const markAsUnseen = async (keys: string[], options?: any): Promise<void> => {
    try {
      if (!options) options = {};
      options.autoSave = options.autoSave != null ? options.autoSave : true;

      await http.post('notifications/markAsUnseen', keys, { headers: options });
    } catch (error) {
      console.error('Error marking notifications as unseen:', error);
      throw error;
    }
  };

  return {
    get,
    count,
    markAsSeen,
    markAsUnseen
  };
};

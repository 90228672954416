import { HttpClient } from '@repo/core/api';
import { AcceptUserInvitationParameters, ChangePasswordParameters } from '@/core/models';
import { ResourceConfig } from '@repo/core/resources';

const http = new HttpClient({ baseURL: ResourceConfig.clientPortalServerBase });

export const useUserAccountService = () => {
  const register = async (parameters: AcceptUserInvitationParameters): Promise<void> => {
    try {
      const response = await http.post('users/register', parameters);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const changePassword = async (parameters: ChangePasswordParameters) => {
    try {
      const response = await http.post('users/changePassword', parameters);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const acceptInvitation = async (id: string, email: string): Promise<void> => {
    try {
      console.log(email);
      const response = await http.post(`userInvitations/acceptInvite?id=${id}&email=${encodeURIComponent(email)}`);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    register,
    acceptInvitation,
    changePassword
  };
};

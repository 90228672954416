import { useScriptTag } from '@vueuse/core';
import { App } from 'vue';
import Elmahio from 'elmah.io.javascript';
import { ResourceConfig } from '@repo/core/resources';
import { AppConstants } from '@/constants';
import { useAppStore, useAuthStore } from '@repo/vue/stores';
import { useRootStore } from '@/stores';

export class SetupPlugin {
  install(app: App) {
    const pinia = app.config.globalProperties.$pinia;
    const router = app.config.globalProperties.$router;

    // stores
    const appStore = useAppStore(pinia);
    const authStore = useAuthStore(pinia);
    const rootStore = useRootStore(pinia);

    appStore.$subscribe(
      (mutation, state) => {
        // set theme
        if (appStore.isIFrame) {
          document.documentElement.className = 'app-light';
          document.documentElement.style.fontSize = AppConstants.defaultScale + 'px';
        } else {
          document.documentElement.className = state.isDark ? 'app-dark' : 'app-light';
          document.documentElement.style.fontSize = AppConstants.defaultScale + 'px';
        }
      },
      { immediate: true }
    );

    // configure elmah
    const logger = new Elmahio({ apiKey: ResourceConfig.elmahApiKey, logId: ResourceConfig.elmahLogId });

    if (ResourceConfig.enableElmahLogging === 'true') {
      window.Elmahio = Elmahio;

      logger.on('message', (msg) => {
        try {
          msg.application = 'dxcloud.vue.portal';
          if (!msg.data) msg.data = [];
          msg.url = location.href;
          if (rootStore.userProfileId) {
            msg.data.push({ key: 'UserProfileId', value: rootStore.userProfileId });
          }
          if (rootStore.practiceId) {
            msg.data.push({ key: 'PracticeId', value: rootStore.practiceId });
          }
          if (rootStore.entityId) {
            msg.data.push({ key: 'EntityId', value: rootStore.entityId });
          }

          msg.url = location.href;
          msg.user = authStore.user?.profile?.email ?? '';
        } catch (e) {
          console.log('Elmah: ', e);
        }
      });

      // log startup info
      // logger.information('DxCloud Portal project started!');
    }

    app.config.errorHandler = (err) => {
      console.error(err);

      if (ResourceConfig.enableElmahLogging === 'true') {
        logger.error(err as string);
      }
    };

    router.isReady().then(async () => {
      // script tag
      const { load } = useScriptTag(
        `${ResourceConfig.clientPortalServerBase}enums`,
        () => {
          console.log('enums loaded successfully');
        },
        { manual: true }
      );

      // load enums
      try {
        await load();
      } catch (e: any) {
        console.error('failed to load enums');
      }
    });
  }
}

import { ref, computed } from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { useUserService } from '@/core/services';
import { User } from '@/core/models';
import { UserConstants } from '@/constants';

export const useUserStore = defineStore('user', () => {
  const userService = useUserService();

  // state
  const currentUser = ref<User>(<User>{});

  // getters
  const isClientUser = computed(() => currentUser.value?.practiceRole === UserConstants.clientUserRole);
  const isAuditUser = computed(() => currentUser.value?.practiceRole === UserConstants.auditUserRole);
  const isPracticeUser = computed(() => Number(currentUser.value?.practiceRole) >= UserConstants.practiceUserRole);
  const isNonPracticeUser = computed(() => Number(currentUser.value?.practiceRole) < UserConstants.practiceUserRole);

  // actions
  const fetchCurrent = async () => {
    const result = await userService.current();
    currentUser.value = result;
    return result;
  };

  const updateCurrentUser = async (data: User) => {
    const result = await userService.updateCurrent(data);
    currentUser.value = result;
    return result;
  };

  const state = { currentUser };
  const getters = {
    isClientUser,
    isAuditUser,
    isPracticeUser,
    isNonPracticeUser
  };
  const actions = {
    fetchCurrent,
    updateCurrentUser
  };

  return { ...state, ...getters, ...actions };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}

import * as _ from 'lodash-es';
import { HttpClient } from '@repo/core/api';
import { Request } from '@/core/models';
import { ResourceConfig } from '@repo/core/resources';

const http = new HttpClient({ baseURL: ResourceConfig.clientPortalServerBase });

export const useRequestService = () => {
  const requests = async (parameters?: string): Promise<Request[]> => {
    parameters = parameters || '';
    try {
      const response = await http.get('requests' + parameters);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const fileRequests = async (fileId: string): Promise<Request[]> => {
    try {
      const response = await http.get(`requests/FileRequests/${fileId}`);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const request = async (key: string, parameters?: string): Promise<Request> => {
    parameters = parameters || '';
    try {
      const response = await http.get(`requests/${key}` + parameters);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const post = async (data: Request[], options: any) => {
    if (!options) options = {};
    options.autoSave = options.autoSave != null ? options.autoSave : true;

    try {
      const response = await http.post(`requests`, data, { headers: options });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const postFiles = async (
    requestId: string,
    path: string,
    action: boolean,
    files: File[],
    actionId: string | null,
    sendNotifications: boolean
  ) => {
    try {
      if (_.sumBy(files, (x) => x.size ?? 0) > 10000000) throw 'Files may not exceed 10MB.';
      const formData = new FormData();
      if (files.length > 0) {
        _.forEach(files, (x) => {
          formData.append('files', x);
        });
      }
      const options = actionId ? `&actionId=${actionId}` : '';
      path = encodeURIComponent(_.toString(path));
      return await http.post(
        `requests/saveFiles?path=${path}&requestId=${requestId}&action=${action}${options}&sendNotifications=${sendNotifications}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const comment = async (requestId: string, comment: string, sendNotification: boolean) => {
    try {
      const response = await http.post(
        `requests/comment/${requestId}?sendNotification=${sendNotification}`,
        JSON.stringify(comment)
      );
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const deleteRequests = async (requests: Request[]) => {
    try {
      const response = await http.delete(`requests/keys`, {
        data: _.join(
          _.map(requests, (x) => x.id),
          ','
        )
      });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const restore = async (requests: Request[]) => {
    try {
      const response = await http.post(`requests/restore`, requests);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    requests,
    request,
    fileRequests,
    post,
    deleteRequests,
    postFiles,
    comment,
    restore
  };
};

import { HttpClient } from '@repo/core/api';
import { File as StoredFile } from '@/core/models';
import { ResourceConfig } from '@repo/core/resources';

const http = new HttpClient({ baseURL: ResourceConfig.serverBase });

export const useDraftworxFileService = () => {
  const list = async (engagementId: string, parameters?: string): Promise<StoredFile[]> => {
    parameters = parameters || '';
    try {
      const response = await http.get('templates/list' + parameters, {
        headers: { ClientId: engagementId }
      });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    list
  };
};

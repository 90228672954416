import { Axios, AxiosRequestConfig, AxiosResponse } from 'axios';
import { api } from '@repo/core/config';

export class HttpClient extends Axios {
  private readonly baseConfig?: AxiosRequestConfig;

  constructor(baseConfig?: AxiosRequestConfig) {
    super(baseConfig);
    this.baseConfig = baseConfig;
  }

  getUri(config?: AxiosRequestConfig): string {
    return api.getUri({ ...this.baseConfig, ...config });
  }

  request<T = any, R = AxiosResponse<T>, D = any>(config: AxiosRequestConfig<D>): Promise<R> {
    return api.request({ ...this.baseConfig, ...config });
  }

  get<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> {
    return api.get(url, { ...this.baseConfig, ...config });
  }

  delete<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> {
    return api.delete(url, { ...this.baseConfig, ...config });
  }

  head<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> {
    return api.head(url, { ...this.baseConfig, ...config });
  }

  options<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> {
    return api.options(url, { ...this.baseConfig, ...config });
  }

  post<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> {
    return api.post(url, data, { ...this.baseConfig, ...config });
  }

  put<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> {
    return api.put(url, data, { ...this.baseConfig, ...config });
  }

  patch<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> {
    return api.patch(url, data, { ...this.baseConfig, ...config });
  }

  postForm<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> {
    return api.postForm(url, data, { ...this.baseConfig, ...config });
  }

  putForm<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> {
    return api.putForm(url, data, { ...this.baseConfig, ...config });
  }

  patchForm<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> {
    return api.patchForm(url, data, { ...this.baseConfig, ...config });
  }
}

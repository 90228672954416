import axios, { CreateAxiosDefaults } from 'axios';
import { ResourceConfig } from '@repo/core/resources';
import { setRequestInterceptor, setResponseInterceptor } from '@repo/core/security';

const config: CreateAxiosDefaults = {
  baseURL: ResourceConfig.serverBase,
  headers: { 'Content-Type': 'application/json' }
};

export const api = axios.create(config);

setRequestInterceptor(api);
setResponseInterceptor(api);

import * as _ from 'lodash-es';
import { HttpClient } from '@repo/core/api';
import { File as StoredFile } from '@/core/models';
import { ResourceConfig } from '@repo/core/resources';

const http = new HttpClient({ baseURL: ResourceConfig.clientPortalServerBase });

export const useFileService = () => {
  const list = async (path: string | undefined): Promise<StoredFile[]> => {
    path = path ? encodeURIComponent(_.toString(path)) : '';
    try {
      const response = await http.get(`files/list?path=${path}`);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const upload = async (path: string, files: File[]) => {
    try {
      if (_.sumBy(files, (x) => x.size) > 10000000) throw 'Files may not exceed 10MB.';
      const formData = new FormData();
      if (files.length > 0) {
        _.forEach(files, (x) => {
          formData.append('files', x);
        });
      }

      path = encodeURIComponent(_.toString(path));
      return await http.post(`files?path=${path}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const deleteFiles = async (files: StoredFile[]) => {
    try {
      const response = await http.delete(`files/Delete`, { data: files });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const download = async (path: string, files: StoredFile[]) => {
    try {
      path = encodeURIComponent(path);
      const response = await http.post(`files/Download?path=${path}`, files, {
        responseType: 'blob'
      });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const createFolder = async (path: string) => {
    try {
      path = encodeURIComponent(path);
      const response = await http.post(`files/CreateFolder?path=${path}`);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const sendToDraftworx = async (
    engagementId: string | undefined,
    draftworxPath: string | undefined,
    path: string | undefined,
    files: StoredFile[]
  ) => {
    try {
      const response = await http.post(
        `files/SendToDraftworx?engagementId=${engagementId}&draftworxPath=${draftworxPath}&path=${path}`,
        files
      );
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const move = async (files: StoredFile[], destinationPath: string) => {
    try {
      destinationPath = encodeURIComponent(destinationPath);
      const response = await http.post(`files/move?destinationPath=${destinationPath}`, files);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const rename = async (path: string, file: StoredFile, name: string) => {
    try {
      path = encodeURIComponent(path);
      const response = await http.post(`files/rename?path=${path}&name=${encodeURIComponent(_.toString(name))}`, file);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const exists = async (paths: string[]): Promise<boolean> => {
    try {
      const response = await http.post(`files/exists?`, paths);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const hasContent = async (paths: string[]): Promise<any[]> => {
    try {
      const response = await http.post(`files/hasContent`, paths);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const search = async (query: string): Promise<StoredFile[]> => {
    try {
      query = encodeURIComponent(query);
      const response = await http.get(`files/search?query=${query}`);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    list,
    upload,
    deleteFiles,
    download,
    sendToDraftworx,
    rename,
    createFolder,
    exists,
    hasContent,
    search,
    move
  };
};
